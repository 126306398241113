@use "variables" as *;

body {
  margin: 0;
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 1.1rem;
  color: $darkGunmetal;
  text-align: left;
  background-color: $lightGrey;
  line-height: 1.85rem;
}

@media screen and (max-width: 1000px) {
  body {
    font-size: 20px;
    line-height: normal;
  }
}

@media screen and (min-width: 2000px) {
  body {
    font-size: 22px;
    line-height: normal;
  }
}

/* ================================================================================================================== */
/*    Cursor                                                                                                          */
/* ================================================================================================================== */
.clickable {
  cursor: pointer !important;
  outline: 0;
}

.clickable:hover .fas {
  color: $lightPurple;
}

.cursor-default {
  cursor: default !important;
}

/* ================================================================================================================== */
/*    Link                                                                                                            */
/* ================================================================================================================== */
a, a * {
  color: $lightPurple;
  text-decoration: none;
  cursor: pointer;
}

a:hover, a:hover *, hover-purple:hover {
  color: $lightPurple;
  text-decoration: underline;
}

/* ================================================================================================================== */
/*    Icon                                                                                                            */
/* ================================================================================================================== */
.icon {
  width: 50px;
  color: #635bff;
}

/* ================================================================================================================== */
/*    Cursor                                                                                                            */
/* ================================================================================================================== */
.cursor-pointer {
  cursor: pointer;
}