/*--------------------------------------------------------------------------------------------------------------------*/
/*  Poppins fonts                                                                                                     */
/*--------------------------------------------------------------------------------------------------------------------*/

/* poppins-100 */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: url('https://cdn.isifid.com/common/fonts/Poppins-Thin.ttf') format('truetype')
}

/* poppins-100italic */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 100;
    src: url('https://cdn.isifid.com/common/fonts/Poppins-ThinItalic.ttf') format('truetype')
}

/* poppins-200 */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: url('https://cdn.isifid.com/common/fonts/Poppins-ExtraLight.ttf') format('truetype')
}

/* poppins-200italic */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 200;
    src: url('https://cdn.isifid.com/common/fonts/Poppins-ExtraLightItalic.ttf') format('truetype')
}

/* poppins-300 */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('https://cdn.isifid.com/common/fonts/Poppins-Light.ttf') format('truetype')
}

/* poppins-300italic */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    src: url('https://cdn.isifid.com/common/fonts/Poppins-LightItalic.ttf') format('truetype')
}

/* poppins-regular */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('https://cdn.isifid.com/common/fonts/Poppins-Regular.ttf') format('truetype')
}

/* poppins-italic */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: url('https://cdn.isifid.com/common/fonts/Poppins-Italic.ttf') format('truetype')
}

/* poppins-500 */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('https://cdn.isifid.com/common/fonts/Poppins-Medium.ttf') format('truetype')
}

/* poppins-500italic */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    src: url('https://cdn.isifid.com/common/fonts/Poppins-MediumItalic.ttf') format('truetype')
}

/* poppins-600 */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('https://cdn.isifid.com/common/fonts/Poppins-SemiBold.ttf') format('truetype')
}

/* poppins-600italic */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    src: url('https://cdn.isifid.com/common/fonts/Poppins-SemiBoldItalic.ttf') format('truetype')
}

/* poppins-700 */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('https://cdn.isifid.com/common/fonts/Poppins-Bold.ttf') format('truetype')
}

/* poppins-700italic */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    src: url('https://cdn.isifid.com/common/fonts/Poppins-BoldItalic.ttf') format('truetype')
}

/* poppins-800 */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: url('https://cdn.isifid.com/common/fonts/Poppins-ExtraBold.ttf') format('truetype')
}

/* poppins-800italic */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 800;
    src: url('https://cdn.isifid.com/common/fonts/Poppins-ExtraBoldItalic.ttf') format('truetype')
}

/* poppins-900 */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: url('https://cdn.isifid.com/common/fonts/Poppins-Black.ttf') format('truetype')
}

/* poppins-900italic */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
    src: url('https://cdn.isifid.com/common/fonts/Poppins-BlackItalic.ttf') format('truetype')
}
