@use "variables" as *;

.mat-button, .mat-raised-button, .btn {
  font-weight: 300 !important;
  border-radius: 17px !important;
  padding: 10px 20px !important;
}

.btn-validate {
  background-color: $lightPurple;
  border: solid 1px $lightPurple;
  border-radius: 10px;
  color: white;
  transition: background-color 0.3s ease-in-out;
}

.btn-validate:hover, .btn-validate:active, .btn-validate:focus {
  background-color: white;
  border: solid 1px $lightPurple;
  box-shadow: 1px 1px 19px rgba(0, 0, 0, 0.06);
  color: $lightPurple;
}

.btn-validate:disabled, .btn-validate[disabled] {
  background-color: #C5CDDA;
  border: solid 1px #C5CDDA;
  color: $black;
}

.btn-return, .btn-return:disabled, .btn-return[disabled] {
  background-color: $black;
  border: solid 1px $black;
  border-radius: 10px;
  color: white;
  transition: background-color 0.3s ease-in-out;
}

.btn-return:hover, .btn-return:active, .btn-return:focus {
  background-color: $darkGrey;
  border: solid 1px $darkGrey;
  box-shadow: 1px 1px 19px rgba(0, 0, 0, 0.06);
}

.btn-black, .btn-black:disabled, .btn-black[disabled] {
  background-color: $black;
  border-color: $black;
  color: white;
  background-image: radial-gradient(circle, #535151 50%, #323030 100%);
}

.btn-black:hover, .btn-black:active, .btn-black:focus {
  color: $black;
  background-color: white;
  background-image: radial-gradient(circle, white 50%, white 100%);
}

// Admin/Advisor button in header
.btn-selected, .btn-selected:disabled, .btn-selected[disabled] {
  background-color: #1C324A;
  border-radius: 23.5px;
  color: white;
  cursor: pointer;
}

.btn-transparent, .btn-transparent:disabled, .btn-transparent[disabled] {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
