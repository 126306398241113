@use "variables" as *;
/*--------------------------------------------------------------------------------------------------------------------*/
/*  Dialog                                                                                                             */
/*--------------------------------------------------------------------------------------------------------------------*/

.dialog-panel {
  border-radius: 28px;
  overflow: auto;
}

.dialog-panel-close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: $lightGrey;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  z-index: 1;
}
