@use "variables" as *;
/*--------------------------------------------------------------------------------------------------------------------*/
/*  Table                                                                                                             */
/*--------------------------------------------------------------------------------------------------------------------*/

.responsive-table {
  .responsive-table-body {
    overflow: auto;

    table {
      th {
        white-space: nowrap;
      }
    }
  }
}
