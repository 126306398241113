@use "variables" as *;
/* ================================================================================================================== */
/*    Colors                                                                                                          */
/* ================================================================================================================== */

.text-black {
  color: $black;
}

.text-light-blue {
  color: $lightBlue;
}

.text-grey {
  color: $darkGrey;
}

.text-dark-blue {
  color: $darkBlue;
}

.text-purple, .hover-purple:hover {
  color: $lightPurple;
}

.text-white {
  color: white;
}

.text-red {
  color: red;
}

/* ================================================================================================================== */
/*    Size                                                                                                            */
/* ================================================================================================================== */

h1, h2, h3 {
  font-weight: bold;
}

h1 {
  font-size: 2.6rem;
  text-align: center;
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.5rem;
}

.small {
  font-size: 1rem;
  line-height: 1;
}

@media screen and (max-width: 1000px) {
  h1 {
    font-size: 33pt;
  }
  h2 {
    font-size: 25pt;
  }

  h3 {
    font-size: 20pt;
  }

  .small {
    font-size: 12pt;
  }
}

@media screen and (min-width: 2000px) {
  h1 {
    font-size: 39pt;
  }
  h2 {
    font-size: 27pt;
  }

  h3 {
    font-size: 22pt;
  }

  .small {
    font-size: 15pt;
  }
}
