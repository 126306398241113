/* ================================================================================================================== */
/*    Colors                                                                                                          */
/* ================================================================================================================== */

$black: #262E37;
$lightGrey: #F8F9FD;
$darkGrey: #7B819B;
$lightBlue: #00AACC;
$darkBlue: #0A2540;
$lightPurple: #635BFF;
$darkGunmetal: #212529;
