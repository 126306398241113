@use "variables" as *;

html {
  --mat-form-field-container-height: 62px !important;
}

.mat-mdc-tooltip {
  width: max(35%, 250px) !important;
  font-size: 0.9rem;
  // Background color
  background-color: $darkBlue;
  --mdc-plain-tooltip-container-color: $darkBlue !important;
  div {
    max-width: 100% !important;
    text-align: center !important;
  }
}

mat-form-field {
  width: 100%;

  input {
    text-align: left;
  }
}

mat-sidenav {
  min-width: 300px;
}
