@use "variables" as *;

/* ================================================================================================================== */
/*  Background                                                                                                        */
/* ================================================================================================================== */

.bg-grey {
  background-color: #EDEBEB;
}

.bg-light {
  background-color: $lightGrey;
}

.bg-dark-blue {
  background-color: $darkBlue !important;
}

.bg-light-blue {
  background-color: #D8E2F3 !important;
}

.favorite {
  background-color: $black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

// Sponsorship tracking
.bg-gender {
  box-shadow: 0 1px 3px 0 #C9CFD4;
  border-radius: 8px 0 0 8px;
}

.bg-male {
  background: rgb(148, 237, 201);
  background: radial-gradient(circle, rgba(148, 237, 201, 1) 0%, rgba(104, 193, 168, 1) 100%);
  width: 15px !important;
}

.bg-female {
  background: #DEAF5B;
  background: radial-gradient(circle, #EBCC94 0%, #DEAF5B 100%);
  width: 15px !important;
}

.bg-no-gender {
  background: #808080;
  background: radial-gradient(circle, #bfbfbf 0%, #808080 100%);
  width: 15px !important;
}

.civ {
  width: 15px;
}

.bg-menu {
  background-image: linear-gradient(180deg, #3B4651 0%, #191E24 100%);
}

.bg-sidenav {
  background-color: #012641 !important;
}

/* ================================================================================================================== */
/*  Borders                                                                                                           */
/* ================================================================================================================== */

// In howto component
@media (min-width: 992px) {
  .border-left {
    padding-left: 8%;
    border-left: 2.5px solid $darkGrey;
  }
}

// News (home and news dialog)
.title-border-left {
  border-left: 1px solid $lightBlue;
}
