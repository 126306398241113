// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@use "assets/scss/variables" as *;
@use "assets/scss/isifid";
// Fonts
@use "assets/font/stylesheet.css" as *;
// quill
@use 'quill/dist/quill.core.css' as *;
@use 'quill/dist/quill.bubble.css' as *;
@use 'quill/dist/quill.snow.css' as *;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
body {
  --primary-color: $black;
  --primary-lighter-color: $lightGrey;
  --primary-darker-color: $lightBlue;
  --text-primary-color: white;
  --text-primary-lighter-color: rgba(black, 0.87);
  --text-primary-darker-color: white;
  --client-color: red;
}

$mat-primary: (
        main: $black,
        lighter: $lightGrey,
        darker: $lightBlue,
        200: $black, // For slide toggle,
        contrast : (
                main: white,
                lighter: rgba(black, 0.87),
                darker: white,
        )
);
$gift-primary: mat.m2-define-palette($mat-primary, main, lighter, darker);
$mat-accent: (
        main: #797979,
        lighter: #d7d7d7,
        darker: #5c5c5c,
        200: #797979, // For slide toggle,
        contrast : (
                main: white,
                lighter: rgba(black, 0.87),
                darker: white,
        )
);
$gift-accent: mat.m2-define-palette($mat-accent, main, lighter, darker);

// The warn palette is optional (defaults to red).
$mat-warn: (
        main: #f35353,
        lighter: #fbcbcb,
        darker: #ed3939,
        200: #f35353, // For slide toggle,
        contrast : (
                main: rgba(black, 0.87),
                lighter: rgba(black, 0.87),
                darker: white,
        )
);
$gift-warn: mat.m2-define-palette($mat-warn, main, lighter, darker);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$gift-theme: mat.m2-define-light-theme((
  color: (
    primary: $gift-primary,
    accent: $gift-accent,
    warn: $gift-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can manage and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($gift-theme);


// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', monospace;

  .mat-badge-content {
    font-family: 'Poppins', Roboto, sans-serif;
  }
}

.t-20 {
  top: 20%;
}

html { height: 100%; }
