@use "variables" as *;

input, select {
  text-align: center;
}

input.mat-mdc-chip-input {
  text-align: start;
}

// Radio & checkbox
input[type='radio'], input[type='checkbox'] {
  box-sizing: border-box;
  appearance: none;
  background: white;
  outline: 1px solid $darkBlue;
  border: 3px solid white;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

label {
  vertical-align: middle;
}

input[type='radio']:checked, input[type='checkbox']:checked {
  background: $lightPurple;
}

input[type='radio']:focus, input[type='checkbox']:focus {
  outline: 1px solid $darkBlue !important;
}

// Select
.form-select {
  background-color: #f5f5f5;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23635BFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='4' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"); //%23635BFF
}

// Input

.input-container:hover {
  background-color: #ececec;
}

.input-container:focus-within {
  background-color: #dcdcdc;
}

// Add class .check-box to label for checkbox
// Disable for mat-label
label:not(.check-box):not(.col-form-label):not(.mdc-floating-label):not(.mat-calendar-hidden-label):not(.mdc-label) {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  color: rgba(0, 0, 0, 0.5);
  transform-origin: left top;
  user-select: none;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),color 150ms cubic-bezier(0.4, 0, 0.2, 1), top 500ms;
}

.input-container {

  background-color: #f5f5f5;
  position: relative;
  border-radius: 4px 4px 0 0;
  height: 56px;
  transition: background-color 500ms;

  input[type='text'],
  input[type='date'],
  input[type='number'],
  input[type='email'],
  input[type='tel'] {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: transparent;
    caret-color: var(--accent-color);
    border: 1px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.42);
    color: rgba(0, 0, 0, 0.87);
    transition: border 500ms;
    padding: 20px 16px 6px;
    font-size: 1rem;
  }

  input[type='text']:focus,
  input[type='date']:focus,
  input[type='number']:focus,
  input[type='email']:focus,
  input[type='tel']:focus {
    outline: none;
    border-bottom-width: 2px;
    border-bottom-color: $darkBlue;
  }

  input[type='text']:focus + label,
  input[type='date']:focus + label,
  input[type='number']:focus + label,
  input[type='email']:focus + label,
  input[type='tel']:focus + label {
    color: var(--accent-color);
  }

  input[type='text']:focus + label, input[type='text'].not-empty + label,
  input[type='date']:focus + label, input[type='date'].not-empty + label,
  input[type='number']:focus + label, input[type='number'].not-empty + label,
  input[type='email']:focus + label, input[type='email'].not-empty + label,
  input[type='tel']:focus + label, input[type='tel'].not-empty + label{
    transform: translateY(-100%) scale(0.75);
  }

  // Invalid input
  input[type='text']:invalid, input[type='text'].ng-invalid:not(.ng-pristine),
  input[type='date']:invalid, input[type='date'].ng-invalid:not(.ng-pristine),
  input[type='number']:invalid, input[type='number'].ng-invalid:not(.ng-pristine),
  input[type='email']:invalid, input[type='email'].ng-invalid:not(.ng-pristine),
  input[type='tel']:invalid, input[type='tel'].ng-invalid:not(.ng-pristine) {
    border-bottom-color: red;
    border-bottom-width: 3px;
  }

  input[type='text']:invalid + label, input[type='text'].ng-invalid:not(.ng-pristine) + label,
  input[type='date']:invalid + label, input[type='date'].ng-invalid:not(.ng-pristine) + label,
  input[type='number']:invalid + label, input[type='number'].ng-invalid:not(.ng-pristine) + label,
  input[type='email']:invalid + label, input[type='email'].ng-invalid:not(.ng-pristine) + label,
  input[type='tel']:invalid + label, input[type='tel'].ng-invalid:not(.ng-pristine) + label {
    color: red;
  }

  // Disabled input
  input:disabled {
    color: #00000061;
    background-color: #e9ecef;
  }
}
